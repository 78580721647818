import styled from 'styled-components'

export const Wrapper = styled.div`
  .main-container {
    position: relative;
    width: calc((100vw - 30px) / 2);
    overflow: hidden;
    height: 100%;
    border: 1px solid
      ${({isATCDisabled = false}) =>
        isATCDisabled
          ? 'var(--brand-primary-light-grey-color)'
          : 'var(--brand-primary-silver-color)'};
    box-sizing: border-box;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-highlight: none;
    -webkit-tap-highlight-color: transparent;
  }
  .content-container-product {
    opacity: ${({outOfStock = false}) => (outOfStock ? 0.4 : 1)};
  }
  .img-container,
  .img-container-second {
    width: 100%;
    object-fit: contain;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    transform: scale(1.1);
    height: calc((100vw - 50px) / 2);
    aspect-ratio: 1;
  }

  .product-info {
    padding: 0 10px;
  }
  .rating {
    margin-top: 9px;
    display: flex;
    color: var(--brand-primary-black-color);
    font-family: var(--brand-font-family-text);
    font-weight: 400;
    font-size: 8px;
    line-height: 9px;
    span {
      margin-right: 2px;
    }
  }
  .ratinginfo {
    display: flex;
    align-items: center;
    margin-left: 2px;
    img {
      width: 7.64px;
      height: 7px;
      object-fit: contain;
    }
  }
  .product-name {
    font-style: normal;
    color: var(--brand-primary-text-color);
    margin: 10px 0;
    /* // TODO: AE - why such checks */
    height: ${({brand = 'lj'}) =>
      brand === 'lj' || brand === 'lj-ae' || brand === 'lj-sa'
        ? 'var(--dls-size-48)'
        : '43px'};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-space;
  }
  .price-ctn {
    display: flex;
    align-items: flex-end;
    margin-top: -5px;
  }
  .price {
    font-style: normal;
    color: var(--brand-primary-text-color);
  }
  .discounted-price {
    text-decoration: line-through;
    font-style: normal;
    color: var(--brand-primary-grey-color);
    margin-left: 5px;
    margin-bottom: 3px;
  }
  .label {
    font-family: var(--brand-font-family);
    margin-left: 20px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: var(--brand-font-weight-400);
    font-size: 9px;
    line-height: 10px;
    color: var(--brand-primary-teal-color);
  }
  .discount-text {
    font-weight: var(--brand-font-weight-normal);
    font-size: 10px;
    line-height: 11px;
    color: var(--brand-primary-teal-color);
    margin-bottom: 10px;
  }

  .whyThisWorks {
    margin-bottom: 0px;
    font-size: 10px;
    line-height: 11px;
    font-weight: var(--brand-font-weight-normal);

    .why-this-work-item {
      margin-bottom: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .key {
      display: inline-block;
      margin-right: var(--dls-size-4);
      color: var(--category-primary-color);
      font-weight: var(--brand-font-weight-bold);
    }
  }

  .atc-btn {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    outline: none;
    font-size: 12px;
    border: var(--brand-primary-black-color);
    font-weight: var(--brand-font-weight-bold);
    font-family: var(--brand-font-family);
    background-color: var(--category-primary-color);
    white-space: nowrap;
    transition: all 0.5s ease;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 100%;
    position: relative;
    color: var(--brand-primary-white-color);
    overflow: hidden;
    -webkit-highlight: none;
    -webkit-tap-highlight-color: transparent;
  }

  .price-top-container {
    display: flex;
  }

  .alternate-rating {
    min-height: 14.5px;
    margin-top: 9px;
  }

  @media (min-width: 768px) {
    margin-bottom: 60px;
    .main-container {
      max-height: 704px;
      border-radius: 30px;
      width: 360px;
      height: 100%;
      transition: all 0.4s ease;
    }
    .product-info {
      padding: 0 30px;
    }
    .main-container:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      /* margin-top: -30px; */

      &[data-isenabled='true'] {
        .img-container-second {
          display: block;
        }
        .img-container {
          display: none;
        }
      }
    }
    .img-container {
      margin-top: 0px;
    }
    .atc-btn {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      height: 60px;
      font-size: 20px;
    }
    .img-hover {
      height: auto;
      aspect-ratio: 4/3;
      .img-container-second {
        display: none;
      }
    }

    .img-container,
    .img-container-second {
      width: 360px;
      height: auto;
      aspect-ratio: 4/3;
    }

    .price-ctn {
      margin-top: 0px;
    }
    .discounted-price {
      margin-bottom: 7px;
    }
    .product-name {
      /* // TODO: AE - MOVE TO CONFIG */
      height: ${({brand = 'lj'}) =>
        brand === 'lj' || brand === 'lj-ae' || brand === 'lj-sa'
          ? '52px'
          : '46px'};
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .rating {
      margin-top: 17px;
      font-size: 16px;
      line-height: 18px;
    }

    .ratinginfo img {
      height: 12px;
      width: 12px;
    }
    .label {
      font-size: 18px;
      line-height: 21px;
    }

    .discount-text {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 20px;
    }

    .whyThisWorks {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 18px;
      font-weight: var(--brand-font-weight-normal);

      .why-this-work-item {
        margin-bottom: 10px;
        &:last-child {
          height: auto;
        }
      }

      .key {
        margin-right: 8px;
        color: var(--category-primary-color);
      }
    }

    .price-top-container {
      display: flex;
    }

    .alternate-rating {
      min-height: 23px;
      margin-top: 17px;
    }
  }
`

export const BestSellerImage = styled.img`
  height: ${(props) => props.imgHeight};
  width: ${(props) => props.imgWidth};
  object-fit: contain;
  margin-left: 10px;
  margin-bottom: 3px;
  @media (min-width: 768px) {
    margin-left: 7px;
    margin-bottom: 0px;
    margin-top: 6px;
  }
`
