import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import {memo, useCallback, useContext, useMemo} from 'react'

import {useCartLoadingContext} from '../../../../Context/CartUpdatingContext'
import {useInternationalizationContext} from '../../../../Context/InternationalizationContext'
import {useLocalisationContext} from '../../../../Context/Localisation/Localisation'
import ProductListingContext from '../../../../Context/ProductListingContext'
import {Spinner} from '../../../../common'
import OptimizedImage from '../../../../common/OptimizedImage'
import RatingStars from '../../../../common/RatingStars/ratingStars'
import Typography from '../../../../common/Typography/Typography'
import {BestSellerImage, Wrapper} from './styles'
import OutOfStockBanner from '../../../../common/OutOfStockBanner'
import {useOutOfStockProduct} from 'src/hooks'

function Card({
  card,
  isMobile,
  cta,
  onAddToCart = noop,
  onNavigation = noop,
  handleDeepLink = noop,
  position,
  sectionName = '',
}) {
  const {
    name = '',
    urlKey,
    image = [],
    price,
    discountedPrice: discounted_price,
    discountText = '',
    whyThisWorks = [],
    label,
    rating,
    bestSellerImg = {},
    altText,
    reviewCount = 0,
    sku = '',
    priceLabel = '',
    discountedPriceLabel = '',
    deeplinkUrl = '',
    outOfStock = false,
  } = card || {}

  const spinnerColor = 'white'
  const BRAND = process.env.NEXT_PUBLIC_BRAND || 'lj'

  const {isCartUpdating, itemBeingAddedToCart} = useCartLoadingContext()

  const showLoader = useMemo(() => {
    return isCartUpdating && itemBeingAddedToCart === sku
  }, [isCartUpdating, itemBeingAddedToCart, sku])
  const {currency} = useInternationalizationContext()
  const {PRODUCT_ADD_CART_LABEL = 'Add to Cart'} = useLocalisationContext()

  const bestSellerImgData = useMemo(() => {
    const {
      src = '',
      height = '',
      width = '',
      desktopSrc = '',
      desktopHeight = '',
      desktopWidth = '',
      altText = '',
    } = bestSellerImg || {}
    return isMobile
      ? {
          src,
          height,
          width,
          altText,
        }
      : {
          src: desktopSrc,
          height: desktopHeight,
          width: desktopWidth,
          altText,
        }
  }, [bestSellerImg, isMobile])

  const showWhyThisWorks = useMemo(() => {
    if (Array.isArray(whyThisWorks)) {
      if (!isEmpty(whyThisWorks)) {
        return true
      }
    }

    return false
  }, [whyThisWorks])

  const handleAddToCart = useCallback(() => {
    onAddToCart(card, sectionName)
  }, [onAddToCart, card, sectionName])

  const handleClick = useCallback(
    () => onNavigation(card, sectionName),
    [card, onNavigation, sectionName]
  )

  const lazyLoadProductImage = position > 4
  const imageToRender = useMemo(() => {
    if (image) {
      return Array.isArray(image) ? image[0] : image
    }

    return ''
  }, [image])

  const productListingConfig = useContext(ProductListingContext)
  const {addToCartBtnConfig = {}} = productListingConfig
  const {
    label: btnLabel = PRODUCT_ADD_CART_LABEL,
    action = 'atc',
    isDisabled: isATCDisabled = false,
    oosLabel = 'Notify Me',
    outOfStockToastMessage = 'Thank you for your request. We will notify you as soon as the product is back in stock.',
  } = addToCartBtnConfig || {}

  const {actions: oosActions} = useOutOfStockProduct({
    outOfStockConfig: {
      outOfStockToastMessage,
      outOfStockDeliveryToastText: '',
    },
    productInfo: card,
  })

  const ctaAction = useCallback(() => {
    if (deeplinkUrl) {
      handleDeepLink('LINK', deeplinkUrl, {...card, source: 'Category'})
      return
    } else if (outOfStock) {
      oosActions?.handleNotifyMe()
      return
    }
    action === 'redirect' ? handleClick() : handleAddToCart()
  }, [deeplinkUrl, action, handleClick, handleAddToCart, handleDeepLink])

  const text = useMemo(() => {
    if (cta?.text) {
      return cta?.text
    } else if (outOfStock) {
      return oosLabel
    }

    return btnLabel
  }, [btnLabel, cta?.text, outOfStock])

  const discountedPriceText = useMemo(() => {
    return discountedPriceLabel || discounted_price
      ? `${currency}${discounted_price}`
      : ''
  }, [currency, discountedPriceLabel, discounted_price])

  const priceText = useMemo(() => {
    return priceLabel || `${currency}${price}`
  }, [currency, price, priceLabel])

  if (isEmpty(card)) {
    return null
  }

  const notBestSeller = isEmpty(bestSellerImg)

  return (
    <Wrapper
      className="card-wrapper rcl-product-card"
      isATCDisabled={isATCDisabled}
      brand={BRAND}
      outOfStock={outOfStock}
    >
      <div className="main-container">
        <OutOfStockBanner outOfStock={outOfStock} top={isMobile ? 50 : 100} />
        <div
          onClick={() => handleClick(urlKey, true)}
          className="content-container-product"
        >
          {isMobile ? (
            <OptimizedImage
              source={imageToRender}
              aspectWidth="155"
              aspectHeight="155"
              desktopViewWidth="THIRD"
              mobileViewWidth="THIRD"
              altText={altText || name}
              loading={lazyLoadProductImage ? 'lazy' : 'eager'}
              customClassName="img-container"
            />
          ) : (
            <div className="img-hover" data-isenabled={!!image?.[1]}>
              <OptimizedImage
                source={imageToRender}
                aspectWidth="360"
                aspectHeight="360"
                desktopViewWidth="THIRD"
                mobileViewWidth="THIRD"
                altText={altText || name}
                loading={lazyLoadProductImage ? 'lazy' : 'eager'}
                customClassName="img-container"
              />
              {Array.isArray(image) && !!image[1] && (
                <OptimizedImage
                  source={image[1]}
                  aspectWidth="360"
                  aspectHeight="360"
                  desktopViewWidth="THIRD"
                  mobileViewWidth="THIRD"
                  altText={altText || name}
                  loading={lazyLoadProductImage ? 'lazy' : 'eager'}
                  customClassName="img-container-second"
                />
              )}
            </div>
          )}
          <div className="product-info">
            {rating ? (
              <div className="rating">
                <RatingStars
                  rating={rating}
                  height={isMobile ? '12' : '18'}
                  width={isMobile ? '12' : '18'}
                />
                <div className="ratinginfo">
                  {rating > 0 && <Typography text={rating} variant="x-small" />}
                  {parseFloat(reviewCount) > 0 && (
                    <>
                      <Typography
                        text={`| (${reviewCount}) `}
                        variant="x-small"
                      />
                      <OptimizedImage
                        source="https://i.mscwlns.co/mosaic-wellness/image/upload/v1657884668/Little%20Joys/PDP/tick.png"
                        aspectWidth="12"
                        aspectHeight="12"
                        desktopViewWidth="TINY"
                        mobileViewWidth="TINY"
                        altText="verified-icon"
                        loading="lazy"
                      />
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="alternate-rating"></div>
            )}
            <div className="product-name">
              <Typography text={name} variant="body-base-regular" />
            </div>
            {!!discountedPriceText ? (
              <div className="price-ctn">
                <div>
                  <Typography
                    text={discountedPriceText}
                    variant="body-base-bold"
                    customClassName="price"
                  />
                </div>
                <div>
                  <Typography
                    text={priceText}
                    variant="body-base-regular"
                    customClassName="discounted-price"
                  />
                </div>
                {!notBestSeller && (
                  <BestSellerImage
                    imgHeight={`${bestSellerImgData.height}px`}
                    imgWidth={`${bestSellerImgData.width}px`}
                    src={bestSellerImgData.src}
                    alt={altText}
                    loading="lazy"
                  />
                )}
              </div>
            ) : (
              <div className="price-top-container">
                <Typography
                  text={priceText}
                  variant="body-base-bold"
                  customClassName="price price-ctn"
                />
                {!notBestSeller && (
                  <BestSellerImage
                    imgHeight={`${bestSellerImgData.height}px`}
                    imgWidth={`${bestSellerImgData.width}px`}
                    src={bestSellerImgData.src}
                    alt={altText}
                    loading="lazy"
                  />
                )}
              </div>
            )}

            <div className="label">{label}</div>
            {discountText && (
              <div className="discount-text">{discountText}</div>
            )}
            {!!showWhyThisWorks && (
              <div className="whyThisWorks">
                {whyThisWorks?.map(({label, text}) => {
                  if (!text || !label) return null
                  return (
                    <div key={label} className="why-this-work-item">
                      <Typography
                        text={label}
                        variant="x-small"
                        customClassName="key"
                      />
                      <Typography text={text} variant="x-small" />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        {!isATCDisabled && (
          <button
            className="atc-btn category-primary-atc-btn"
            onClick={ctaAction}
            data-location="product-card"
          >
            {showLoader ? (
              <Spinner color={spinnerColor} />
            ) : (
              <Typography text={text} variant="cta-label-sm" />
            )}
          </button>
        )}
      </div>
    </Wrapper>
  )
}

export default memo(Card)
